.Main-content{
  display: flex;
  align-items: flex-start;
  margin-top: 84px;
  margin-left: 200px;
}
.Nav{
  width: 200px;
  height: -webkit-fill-available;
  display: flex;
  position: fixed;
  left: 0;
  flex-direction: column;
  background-color: rgb(244, 245, 247);
}
.Nav-active{
  background-color: rgba(0, 0, 0, 0.08);
}
.Nav a{
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
}
.Nav a:hover{
  background-color: rgba(0, 0, 0, 0.08);
}
.Nav-movies{
  margin: 0 0 0 10px;
}
.Nav-shows{
  margin: 0 0 0 20px;
}
.Nav-saved{
  margin: 0 0 0 8px;
}
.Movie-content{
  flex:1;
  padding: 20px;
  min-height: 84vh;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.movie-listing__blocks{
  display: flex;
  flex-wrap: wrap;
}
.notFound{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  flex-direction: column;
}
.movie-listing__error{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 83vh;
}
.details{
  box-sizing: BORDER-BOX;
  overflow: auto;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.details-body{
  display: flex;
  flex-direction: column;
  max-width: 95%;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}
.details-cast{
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  margin: 30px auto;
}
.details-saver{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details-additional{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.details-additional p{
  margin: 0 8px;
}
.cast-single{
  width: 170px;
  background-color: #fff;
  box-sizing: border-box;
  margin:0 10px 10px 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}
.cast-single__info{
  text-align: center;
  min-height: 100px;
  padding: 10px 0;
  background-color: rgb(244, 245, 247);
}
.cast-single__info .character{
  text-overflow: ellipsis;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  margin: 5px auto;
}
.cast-single__info .name{
  margin: 0;
}
.movie-listing__header{
  border-top: 1px solid #ccc;
  padding-top: 40px;
}
.movie-listing .no-saved{
  margin: 0;
}
@media (max-width: 480px) and (min-width: 320px){
  .Nav{
    width: 100px;
  }
  .Nav-text{
    display: none;
  }
  .Main-content{
    margin-left: 100px;
  }
  .details-body{
    margin: 0;
    padding: 10px;
  }
  .details-saver{
    flex-direction: column;
  }
  .details-cast{
    justify-content: center;
  }
  .movie-listing__blocks >div{
    margin-right: 0;
  }
}
